<template>
  <h1>Erro</h1>
</template>

<script>
export default {
  props: {
    color: String,
  },
}
</script>

<style scoped>
  svg {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
</style>
