<template>
  <div class="made-by-component text--center pb-3">
    <span>
      ©2021. Made by
      <a
        href="#"
        class="link"
      >Visiolens</a>
    </span>
  </div>
</template>

<script>
export default {
  name: 'MadeBy',
}
</script>

<style lang="scss">
.made-by-component {
  position: absolute;
  bottom: 0;
  width: 100%;
}
</style>
